<template>
  <Container>
    <div class="px-5">
      <DxGridWithSearch
        gridName="molecularTestRuns"
        :dataSource="runs"
        :columns="columns"
        title="Test Runs"
        :toolbar="toolbar"
        @initialized="initGrid"
        :isLoading="isLoading"
      >
        <template v-slot:extraActions>
          <div class="d-flex align-items-center">
            <Loader v-if="isLoading" size="small" class="mr-2" />
            <add-button
              v-if="permissions.TestRunCreateEdit"
              @click="addRun"
              v-tooltip="'Click to create new run.'"
            />
          </div>
        </template>
        <template v-slot:actions-cell="{ data }">
          <div class="button-group d-flex">
            <icon-button
              v-if="permissions.TestRunCreateEdit"
              v-tooltip.left="'Edit run.'"
              @click="editRun(data)"
              class="m-auto text-primary pointer p-0"
              :icon="'pen-alt'"
            />

            <icon-button
              v-if="permissions.TestRunQueue"
              @click="queueRun(data)"
              v-tooltip.left="'Queue run.'"
              class="m-auto text-primary pointer p-0"
              :icon="'list-ol'"
            />
            <icon-button
              v-if="permissions.TestRunDelete"
              @click="deleteRun(data)"
              v-tooltip.left="'Delete run.'"
              class="m-auto text-danger pointer p-0"
              :icon="'trash-alt'"
            />
          </div>
        </template>
      </DxGridWithSearch>
    </div>
    <Modal :status="isAddEditPopupOpen" @close="closeAddEditPopup" maxWidth="50vw">
      <AddEditRun :selectedRun="selectedRun" @cancel="closeAddEditPopup" @submit="handleSubmit" />
    </Modal>
  </Container>
</template>

<script>
import Container from "@/components/common/Container.vue";
import DxGridWithSearch from "@/components/common/DxGridWithSearch.vue";
import AddButton from "@/components/common/AddButton.vue";
import IconButton from "@/components/common/IconButton.vue";
import Modal from "@/components/common/Modal";
import AddEditRun from "@/components/AddEditRun";
import { mapGetters } from "vuex";
import { TestRunApi } from "@/services/index";
import { handleErrors } from "@/modules/handleErrors";
import Loader from "./common/Loader.vue";
import { formatDatetimeCell } from "@/modules/helpers";
import { format } from "date-fns";

export default {
  name: "TestRuns",
  metaInfo: {
    title: "Test Runs",
    titleTemplate: "IntelliPath - %s"
  },
  components: { Container, DxGridWithSearch, AddButton, IconButton, Modal, AddEditRun, Loader },
  data() {
    return {
      isAddEditPopupOpen: false,
      selectedRun: null,
      grid: {},
      runs: [],
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    columns() {
      return [
        { dataField: "runName" },
        { dataField: "status" },
        {
          dataField: "createdOn",
          dataType: "date",
          calculateDisplayValue: data => {
            if (data.createdOn) {
              return this.calculateLocalDate(data.createdOn);
            }
            return "";
          }
        },
        {
          dataField: "queuedOn",
          dataType: "date",
          calculateDisplayValue: data => {
            if (data.queuedOn) {
              return this.calculateLocalDate(data.queuedOn);
            }
            return "";
          }
        },
        {
          dataField: "sentOn",
          dataType: "date",
          calculateDisplayValue: data => {
            if (data.sentOn) {
              return this.calculateLocalDate(data.sentOn);
            }
            return "";
          }
        },
        {
          dataField: "resultedOn",
          dataType: "date",
          calculateDisplayValue: data => {
            if (data.resultedOn) {
              return this.calculateLocalDate(data.resultedOn);
            }
            return "";
          }
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions-cell",
          visible:
            this.permissions.TestRunCreateEdit ||
            this.permissions.TestRunQueue ||
            this.permissions.TestRunDelete
        }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            location: "after",
            template: "extraActions"
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-list-ol",
              onClick: this.queueSelectedRuns,
              hint: "Click to queue selected runs",
              elementAttr: {
                class: "icon-color"
              },
              text: "Queue runs"
            },
            locateInMenu: "auto",
            showText: "inMenu",
            visible: this.permissions.TestRunQueue
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-trash-alt",
              onClick: () => this.deleteSelected(),
              type: "danger"
            },
            visible: this.permissions.TestRunDelete
          }
        ]
      };
    }
  },
  mounted() {
    this.loadRuns();
  },
  methods: {
    initGrid({ component }) {
      this.grid = component;
    },
    addRun() {
      this.isAddEditPopupOpen = true;
    },
    editRun({ data }) {
      this.isAddEditPopupOpen = true;
      this.selectedRun = data;
    },
    async queueRun({ data }) {
      try {
        this.isLoading = true;
        await TestRunApi.queueTestRun(data.id);
        window.notify(`Queued ${data.runName}`);
        this.loadRuns();
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    closeAddEditPopup() {
      this.isAddEditPopupOpen = false;
      this.selectedRun = null;
    },
    async queueSelectedRuns() {
      const selectedRuns = this.grid.getSelectedRowsData();
      if (!selectedRuns.length) {
        window.alert("Please select runs to queue.");
        return;
      }
      const runsIdToQueue = selectedRuns.map(e => e.id);
      let numberOfErrors = 0;
      this.isLoading = true;
      for (const runId of runsIdToQueue) {
        try {
          await TestRunApi.queueTestRun(runId);
        } catch {
          numberOfErrors++;
        }
      }
      this.isLoading = false;
      if (!numberOfErrors) {
        window.notify(`Queued ${runsIdToQueue.length} runs.`);
      } else {
        window.notify(
          `Successfully queued ${
            runsIdToQueue.length - numberOfErrors
          } runs. ${numberOfErrors} runs were unable to be successfully queued.`
        );
      }
      this.loadRuns();
    },
    async deleteSelected() {
      const selectedRuns = this.grid.getSelectedRowsData();
      if (!selectedRuns.length) {
        window.alert("Please select runs to delete.");
        return;
      }
      const confirm = await window.confirm("Are you sure you want to delete the selected runs?");
      if (!confirm) {
        return;
      }
      const idsToDelete = selectedRuns.map(e => e.id);
      let numberOfErrors = 0;
      this.isLoading = true;
      for (const runId of idsToDelete) {
        try {
          await TestRunApi.deleteTestRun(runId);
        } catch {
          numberOfErrors++;
        }
      }
      if (!numberOfErrors) {
        window.notify(`Successfully deleted ${idsToDelete.length} runs.`);
      } else {
        window.notify(
          `Successfully deleted ${
            idsToDelete.length - numberOfErrors
          } runs. ${numberOfErrors} runs could not be deleted.`,
          "warning"
        );
      }
      this.isLoading = false;
      this.loadRuns();
    },
    async deleteRun({ data }) {
      const confirm = await window.confirm("Are you sure you want to delete this run?");
      if (!confirm) {
        return;
      }
      try {
        this.isLoading = true;
        await TestRunApi.deleteTestRun(data.id);
        window.notify("Run deleted.");
        this.loadRuns();
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    loadRuns() {
      TestRunApi.getTestRuns().then(res => {
        this.runs = res.data;
      });
    },
    handleSubmit() {
      this.closeAddEditPopup();
      this.loadRuns();
    },
    calculateLocalDate(date) {
      if (date) {
        const formattedDate = formatDatetimeCell(date);
        return format(formattedDate, "M/dd/yyyy");
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped></style>
