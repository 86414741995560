<template>
  <div>
    <h2>{{ isEditing ? "Edit" : "Add" }} Run</h2>
    <div class="d-flex justify-content-start">
      <TextInput
        class="mx-2"
        label="Run Name"
        v-model="run.runName"
        ref="runName"
        :validator="$v.run.runName"
      />
    </div>
    <DxGridWithSearch
      title="Cases"
      :dataSource="run.specimens"
      :searchPanel="null"
      :columnChooser="false"
      :columns="columns"
      :toolbar="toolbar"
      :editing="editing"
      @initialized="initGrid"
      :selection="null"
      :showFilterRow="false"
    >
      <template v-slot:extraActions>
        <add-button @click="addSpecimenTest" v-tooltip="'Click to add specimen test.'" />
      </template>
      <template v-slot:actions-cell="{ data }">
        <icon-button
          v-tooltip.left="'Remove specimen test.'"
          @click="removeSpecimenTest(data)"
          class="m-auto text-danger pointer p-0"
          :icon="'trash-alt'"
        />
      </template>
    </DxGridWithSearch>
    <SubmitCancelRow @submit="handleSubmit" @cancel="handleCancel" />
  </div>
</template>

<script>
import TextInput from "@/components/common/TextInput.vue";
import { cloneDeep } from "lodash";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";
import DxGridWithSearch from "@/components/common/DxGridWithSearch.vue";
import IconButton from "@/components/common/IconButton.vue";
import AddButton from "@/components/common/AddButton.vue";
import { scanCaseBarcode } from "@/modules/scanCaseBarcode";
import CasesApi from "@/services/cases";
import { SpecimensApi, TestRunApi } from "@/services";
import { handleErrors } from "@/modules/handleErrors";
import { required } from "vuelidate/lib/validators";

export default {
  props: ["selectedRun"],
  components: { TextInput, SubmitCancelRow, DxGridWithSearch, IconButton, AddButton },
  data() {
    return {
      run: {
        runName: "",
        specimens: []
      },
      editing: {
        allowUpdating: true,
        allowDeleting: true,
        confirmDelete: true,
        mode: "cell",
        useIcons: true,
        startEditAction: "click"
      },
      grid: {}
    };
  },
  computed: {
    isEditing() {
      return Boolean(this.selectedRun);
    },
    columns() {
      return [
        {
          dataField: "caseNumber",
          allowEditing: true,
          setCellValue: async (newData, newCaseNumber) => {
            if (!newCaseNumber) {
              newData.caseNumber = "";
              newData.caseId = null;
              return;
            }
            const { caseNumber } = scanCaseBarcode(newCaseNumber);
            if (!caseNumber) {
              window.alert("Please enter a valid case number.");
              return;
            }
            const caseData = await CasesApi.searchStore.load({
              filter: ["accessionNumber", "=", caseNumber]
            });
            if (!caseData?.length) {
              window.alert("Case not found.");
              return;
            }
            const specimens = await SpecimensApi.getSpecimen(caseData[0].caseId);
            if (!specimens) {
              window.alert(`No specimens found for case ${caseData[0].accessionNumber}`);
              return;
            }
            newData.caseNumber = caseData[0].accessionNumber;
            newData.caseId = caseData[0].caseId;
            newData.specimenId = specimens[0].id;
          }
        },
        { dataField: "testName", allowEditing: true },
        { dataField: "diagnosis", allowEditing: true },
        { dataField: "caseId", visible: false },
        { dataField: "specimenId", visible: false },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions-cell"
        }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            location: "after",
            template: "extraActions"
          }
        ]
      };
    }
  },
  mounted() {
    if (this.selectedRun) {
      this.run = cloneDeep(this.selectedRun);
    } else {
      this.addSpecimenTest();
    }
  },
  validations() {
    return {
      run: {
        runName: {
          required
        }
      }
    };
  },
  methods: {
    initGrid({ component }) {
      this.grid = component;
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return window.notify("Please check your input & try again.", "error");
      }
      if (!this.run.specimens.length) {
        await window.alert("No cases selected.");
        this.addSpecimenTest();
        return;
      }
      try {
        if (this.run?.id) {
          await TestRunApi.updateTestRun(this.run);
          window.notify("Updated test run.");
        } else {
          await TestRunApi.createTestRun(this.run);
          window.notify("Created test run.");
        }
        this.$emit("submit");
      } catch (error) {
        handleErrors(error);
      }
    },
    handleCancel() {
      this.$emit("cancel");
    },
    addSpecimenTest() {
      this.run.specimens.push({
        caseNumber: "",
        testName: "",
        diagnosis: "",
        caseId: null,
        specimenId: null
      });
      const index = this.run.specimens.length - 1;
      this.focusCell(index);
    },
    removeSpecimenTest(data) {
      const { rowIndex } = data;
      this.run.specimens.splice(rowIndex, 1);
    },
    focusCell(rowIndex = 0, dataField = "caseNumber") {
      setTimeout(() => this.grid.editCell(rowIndex, dataField), 500);
    }
  }
};
</script>

<style lang="scss" scoped></style>
